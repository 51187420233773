import React from 'react';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';

import SimpleService from './Common/SimpleService';
import RegionSelect from './Common/Google/RegionSelect';

export default function PlacesByDomainsSearch() {
  const { t } = useTranslation();

  return (
    <SimpleService
      unitName='domain'
      defaultLimit={1}
      title='Google Maps Search by Domains'
      subTitle='Finds places by domains'
      serviceName='google_places_domain_search_service'
      queriesPlaceholder='dominopark.com&#10;https://www.esbnyc.com/'
      queriesLabel='Domains or URLs'
      apiTag='Google/paths/~1google-places-by-domain/get'
      supportedEnrichmentServices={[]}
      serviceOntologies={['domain', 'phone', 'address']}
      ExtraItems={({ updateTask, task }) => {
        const { region } = task;

        return <>
          <Form.Item className='formItem'>
            <Form.Item label={t('title.country', 'Country')} className='inlineFormItem'>
              <RegionSelect size='default' value={region} onChange={(value) => updateTask({ region: value })}/>
            </Form.Item>
          </Form.Item>
        </>;
      }}
    />
  );
}
