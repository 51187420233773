import jwt_decode from 'jwt-decode';

import apiClient from '../api/apiClient';
import apiProfile from '../api/apiProfile';
import { updateLocalStorage } from '../utils/utils';
import Mixpanel from '../analytics/mixpanel';

export default class Auth {
  constructor() {
    this.setSession = this.setSession.bind(this);
    this.renewSession = this.renewSession.bind(this);
    this.logout = this.logout.bind(this);
    this.isAdmin = this.isAdmin.bind(this);
  }

  setSession(accessToken, useRedirect=false) {
    const payload = jwt_decode(accessToken);
    const { expires_at, email, picture, email_verified, to_email, roles, region, ie, id, kyc } = payload || {};

    apiClient.defaults.headers.common = { Authorization: `Bearer ${accessToken}` };

    localStorage.setItem('isLoggedIn', '1');
    localStorage.setItem('expiresAt', expires_at * 1000);
    localStorage.setItem('accessToken', accessToken);
    updateLocalStorage('email', email);
    updateLocalStorage('toEmail', to_email);
    updateLocalStorage('emailVerified', email_verified);
    updateLocalStorage('id', id);
    updateLocalStorage('avatar', picture);
    updateLocalStorage('roles', roles ? roles.join() : null);
    updateLocalStorage('re', region);
    updateLocalStorage('ie', ie);

    Mixpanel.identify(id);

    if (useRedirect) {
      if (kyc) {
        localStorage.setItem('kyc', 1);
        window.location = '/signup-confirm';
      } else {
        const redirect = localStorage.getItem('redirect');
        if (redirect) {
          localStorage.removeItem('redirect');
          window.location = redirect;
        } else {
          window.location = '/';
        }
      }
    }
  }

  async renewSession() {
    const { data } = await apiProfile.refreshAuthToken(localStorage.getItem('accessToken'));
    this.setSession(data.access_token);
  }

  logout(email=null) {
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('expiresAt');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('email');
    localStorage.removeItem('toEmail');
    localStorage.removeItem('avatar');
    localStorage.removeItem('roles');
    localStorage.removeItem('ie');
    localStorage.removeItem('re');
    localStorage.removeItem('emailVerified');
    localStorage.removeItem('id');
    localStorage.removeItem('provider');

    if (email) window.location = `/login?email=${email}`;
    else window.location = '/login';
  }

  isAdmin() {
    if (this.roles) {
      return this.roles.includes('admin');
    }
    return false;
  }

  get authenticated() {
    const expiresAt = localStorage.getItem('expiresAt');
    if (expiresAt) {
      return new Date().getTime() < Number(expiresAt);
    }

    return false;
  }

  get profile() {
    let region = localStorage.getItem('re');

    return {
      email: localStorage.getItem('email'),
      userId: localStorage.getItem('id'),
      avatar: localStorage.getItem('avatar'),
      roles: this.roles,
      emailVerified: localStorage.getItem('emailVerified') === 'true',
      ie: localStorage.getItem('ie'),
      provider: localStorage.getItem('provider'),
      region: region === 'undefined' ? null : region,
    };
  }

  get roles() {
    const value = localStorage.getItem('roles');
    return value ? value.split(',') : [];
  }
}
