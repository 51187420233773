import React from 'react';
import { Form, Checkbox, InputNumber, Tooltip, Select } from 'antd';
import PropTypes from 'prop-types';
import qs from 'query-string';
import { QuestionCircleTwoTone, MailOutlined } from '@ant-design/icons';
import { cloneDeep, isEmpty, debounce } from 'lodash';
import PremiumFeatureTooltip from '../../shared/Components/PremiumFeatureTooltip';
import { splitTaskByKey } from '../../utils/utils';
import { useSelector } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import config from 'react-global-configuration';

import history from '../../utils/history';
import { addUrlParams } from '../../utils/utils';
import { getDefaultRegion, onSetDefaultRegion, getDefaultLanguage, onSetDefaultLanguage, getDefaultExactMatch } from '../../utils/defaultProps';
import { formatTaskQueries, deformatTaskQueries, isMapsTaskReady } from './Common/Google/utils';
import { toArray } from '../../utils/utils';
import { isAdvancedUser } from '../../utils/localDB';
import InfoTooltip from '../../shared/Components/InfoTooltip';
import BaseService from './Common/BaseService';
import AdvancedParameters from './Common/AdvancedParameters';
import QueriesFormItems from './Common/Google/QueriesFormItems';
import SelectService from './Pipeline/SelectService';
import LanguageSelect from './Common/Google/LanguageSelect';
import RegionSelect from './Common/Google/RegionSelect';
import OrganizationsPerQueryInput from './Common/Google/OrganizationsPerQueryInput';
import Filters from './Common/Google/Filters';

const { Option } = Select;

const queriesSoftLimit = config.get('queriesSoftLimit');
const LOCATIONS_DELIMITER = '>';

const CUSTOM_QUERIES_KEY = 'customQueries';
const COLUMNS = [
  'query', 'name', 'name_for_emails', 'site', 'subtypes', 'category', 'type', 'phone',
  'full_address', 'borough', 'street', 'city', 'postal_code', 'area_service', 'state', 'us_state', 'country', 'country_code', 'latitude', 'longitude', 'h3', 'time_zone', 'plus_code',
  'rating', 'reviews', 'reviews_link', 'reviews_tags', 'reviews_per_score', 'reviews_per_score_1', 'reviews_per_score_2', 'reviews_per_score_3', 'reviews_per_score_4', 'reviews_per_score_5',
  'photos_count', 'photo', 'street_view', 'located_in',
  'working_hours', 'working_hours_old_format', 'other_hours', 'popular_times', 'business_status', 'about', 'range', 'posts', 'logo', 'description', 'typical_time_spent',
  'verified', 'owner_id', 'owner_title', 'owner_link',
  'reservation_links', 'booking_appointment_link', 'menu_link', 'order_links',
  'location_link',
  'place_id', 'google_id', 'cid', 'reviews_id', 'located_google_id'
];
export const enrichmentFields = ['google_id', 'place_id', 'company name', 'legal name', 'business name', 'company', 'business', 'name', 'address', 'city', 'state', 'zip code', 'country'];
const domainsService = 'domains_service';
const companyInsightsService = 'company_insights_service';
const whitepagesPhonesService = 'whitepages_phones';
const emailsValidatorService = 'emails_validator_service';
const phonesEnricherService = 'phones_enricher_service';
const defaultEnrichments = [domainsService, companyInsightsService, emailsValidatorService, phonesEnricherService];
const checkboxesPaddingRight = 20;

export default function GoogleMapsScraper({ version }) {
  const {
    hl: urlLanguage, query: urlQuery, gl: urlRegion, c: urlCategories, l: urlLocations,
    e: urlEnrichments, z: urlUseZipCodes, queryLimit: urlQueryLimit, limit: urlLimit,
    dd: urlDropDuplicates,
  } = qs.parse(history.location.search);
  const defaultLanguage = getDefaultLanguage(urlLanguage);
  const taskExtraDefaultParams = {
    categories: urlCategories ? toArray(urlCategories) : [],
    locations: urlLocations ? toArray(urlLocations) : [],

    enrichments: urlEnrichments ? toArray(urlEnrichments) : localStorage.getItem('googleMapsEnrichments') ? JSON.parse(localStorage.getItem('googleMapsEnrichments')) : (localStorage.getItem('appsumo') ? [domainsService] : defaultEnrichments),

    customCategories: '',
    customLocations: '',

    language: defaultLanguage,
    region: urlRegion ? urlRegion : getDefaultRegion(urlRegion) || 'US',

    limit: parseInt(urlLimit) || 0,
    organizationsPerQueryLimit: parseInt(urlQueryLimit) || 500,

    filters: [],
    exactMatch: defaultLanguage === 'en' ? getDefaultExactMatch(false) : false,
    useZipCodes: urlUseZipCodes === 'true',

    dropDuplicates: urlDropDuplicates === 'true' ? urlDropDuplicates : localStorage.getItem('dropDuplicates') !== '0',
    dropEmailDuplicates: false,
    ignoreWithoutEmails: false,

    UISettings: {
      isCustomQueries: urlQuery  ? true : (localStorage.getItem(CUSTOM_QUERIES_KEY) === '1' && !urlCategories && !urlLocations),
      isCustomCategories: false,
      isCustomLocations: false,
    },
  };

  const countriesLocations = useSelector(state => state.queriesReducer.countryLocations) || {};
  const { t } = useTranslation();
  var updateTimer = null;

  function formatTask(task) {
    const { UISettings = {}, settings = {}, enrich } = task;
    const { output_columns = [] } = settings;
    settings.output_columns =  [...new Set(output_columns)];

    const { isCustomQueries, isCustomCategories, isCustomLocations } = UISettings;

    if (enrich) {
      task.filters = [];
      task.organizationsPerQueryLimit = 1;
      task.dropDuplicates = false;
      task.dropEmailDuplicates = false;
      task.ignoreWithoutEmails = false;
      task.region = undefined;
    }

    return formatTaskQueries({
      ...task, limit: task.limit || 0,
    }, isCustomQueries, isCustomCategories, isCustomLocations);
  }

  function deformatTask(task) {
    const { UISettings = {} } = task;
    const { isCustomQueries, isCustomCategories, isCustomLocations } = UISettings;
    return deformatTaskQueries(task, isCustomQueries, isCustomCategories, isCustomLocations);
  }

  function splitTask(task, splitInto) {
    if (!isEmpty(task.queries)) {
      return splitTaskByKey(task, splitInto, 'queries');
    } else if (!isEmpty(task.locations) && !isEmpty(task.categories)) {
      const { locations, categories, useZipCodes, region, UISettings } = task;
      const { isCustomLocations } = UISettings;

      if (isCustomLocations) return splitTaskByKey(task, splitInto, 'locations');

      const results = [];
      const countryLocations = countriesLocations[region].reduce((a, b) => ({ ...a, [b.value]: b.children}), {});
      const categoriesAmount = categories.length;
      let oneTaskLocations = [];
      let oneTaskQueriesAmount = 0;

      for (const location of locations) {
        const locationQueriesAmount = getTotalSubLocationsAmount(location, useZipCodes, countryLocations) * categoriesAmount;
        const subLocations = (locationQueriesAmount < queriesSoftLimit) ? [location] : getSubLocations(location, countryLocations);

        for (const subLocation of subLocations) {
          const subLocationQueriesAmount = getTotalSubLocationsAmount(subLocation, useZipCodes, countryLocations) * categoriesAmount;

          if (oneTaskQueriesAmount + subLocationQueriesAmount < queriesSoftLimit) {
            oneTaskLocations.push(subLocation);
            oneTaskQueriesAmount += subLocationQueriesAmount;
          } else {
            results.push(cloneSubTask(task, oneTaskLocations, results.length));
            oneTaskLocations = [subLocation];
            oneTaskQueriesAmount = subLocationQueriesAmount;
          }
        }
      }

      if (oneTaskLocations.length > 0) results.push(cloneSubTask(task, oneTaskLocations, results.length));

      return results;
    }
  }

  function cloneSubTask(task, locations, index) {
    const newTask = cloneDeep(task);
    const { tags = [] } = newTask;

    const formatedTags = typeof tags === 'string' ? tags.split(', ') : tags;
    const locationsTag = locations.length == 1 ? locations[0] : `${locations[0]}...${locations[locations.length - 1]}`;

    return {
      ...newTask,
      tags: [...formatedTags, `subtask #${index + 1} (${locationsTag})`],
      locations,
    };
  }

  function getTotalSubLocationsAmount(location, useZipCodes, countryLocations) {
    if (location in countryLocations) {
      if (useZipCodes) {
        return countryLocations[location].reduce((a, b) => a + b.children.length, 0);
      } else {
        return countryLocations[location].length;
      }
    } else {
      const locationParts = location.split(LOCATIONS_DELIMITER);

      if (locationParts.length === 4) {
        return 1;
      } else if (locationParts.length === 3) {
        if (!useZipCodes) {
          return 1;
        } else {
          for (const subLocation of countryLocations[locationParts.slice(0, 2).join(LOCATIONS_DELIMITER)]) {
            if (subLocation.value === location) return subLocation.children.length;
          }
        }
      }

      return 0;
    }
  }

  function getSubLocations(location, countryLocations) {
    if (location in countryLocations) {
      return countryLocations[location].map(r => r.value);
    } else {
      const locationParts = location.split(LOCATIONS_DELIMITER);
      for (const subLocation of countryLocations[locationParts.slice(0, 2).join(LOCATIONS_DELIMITER)]) {
        if (subLocation.value === location) return subLocation.children.map(r => r.value);
      }
      return [location];
    }
  }

  function updateUrlParams(params) {
    const { UISettings = {}, categories, locations, region, language, enrichments, limit, dropDuplicates, useZipCodes } = params;
    const { isCustomCategories, isCustomLocations } = UISettings;

    const urlParams = {
      c: (categories && categories.length <= 25 && !isCustomCategories) ? categories : [],
      l: (locations && locations.length <= 25 && !isCustomLocations) ? locations : [],
      e: (enrichments && enrichments.length <= 25) ? enrichments : [],
      z: useZipCodes ? true : [],
      limit: limit ? limit : [],
      dd: dropDuplicates ? true : [],
      gl: region ? region : [],
      hl: language ? language : [],
    };

    window.history.replaceState(null, null, `?${addUrlParams(location.search, urlParams)}` );
  }

  return (
    <BaseService
      title='Google Maps Data Scraper'
      subTitle='Returns places data from Google Maps'
      videoTutorialLink='https://www.youtube.com/embed/mUxWvsksGJ8'
      tutorialLink='https://outscraper.com/how-to-scrape-google-maps'
      serviceName={version === 2 ? 'google_maps_service_v2' : 'google_maps_service'}
      unitName='place'
      tourSteps={[
        {
          target: () => document.querySelector('.categories-select'),
          title: t('tour.categoriesSelect', 'The first step will be selecting categories or brands of places you want to export.'),
        },
        {
          target: () => document.querySelector('.locations-select'),
          title: t('tour.locationsSelect', 'Secondly, you need to select locations where you want to search for places.'),
        },
        {
          target: () => document.querySelector('.plain-queries'),
          title: t('tour.plainQueries', 'Alternatively, you can switch to plain queries to search for places as you would search them on Google Maps. However, in most cases, it\'s important to use locations inside queries (e.g., bars, NY, USA) as the IP addresses of Outscraper\'s servers might be located in different countries.'),
        },
        {
          target: () => document.querySelector('.limit-select'),
          title: t('tour.limitSelect', 'Do you want to cap your limit or do you need all the places by your search criteria? You can set the limit here.'),
        },
        {
          target: () => document.querySelector('.enrichments-select'),
          title: t('description.enrichment.title', 'Here you can enrich places with other services') + ':',
          description: <>
            ✉️ <Trans i18nKey='description.enrichment.findEmails'>Find email addresses</Trans><br/>
            🌐 <Trans i18nKey='description.enrichment.socialMedia'>Find social media profiles</Trans><br/>
            📨 <Trans i18nKey='description.enrichment.validateEmails'>Validate emails</Trans><br/>
            ☎️ <Trans i18nKey='description.enrichment.validatePhones'>Validate phones</Trans><br/>
            ...<br/>
            <a target='_blank' rel='noopener noreferrer' href='https://outscraper.com/enrichment-services/'><Trans i18nKey='action.learnMore'>Learn more</Trans></a>
          </>,
        }
      ]}
      fields={COLUMNS}
      learnMoreUrl='https://outscraper.com/google-maps-scraper'
      apiTag='Google/paths/~1maps~1search-v3/get'
      taskExtraDefaultParams={taskExtraDefaultParams}
      isTaskReady={isMapsTaskReady}
      formatTask={formatTask}
      splitTask={splitTask}
      deformatTask={deformatTask}
      taskUpdateAfterSubmit={{ queries: '', categories: [], customCategories: '', input_file: null, enrich: false, tags: [] }}
      FormBody={({ task, updateTask }) => {
        const { region, enrichments, UISettings = {} } = task;
        const { isCustomQueries, isCustomCategories, isCustomLocations } = UISettings;

        function onUpdateTask(value) {
          clearTimeout(updateTimer);
          updateTimer = setTimeout(() => {
            updateUrlParams({ ...task, ...value });
          }, 1000);

          updateTask(value);
        }

        function setIsCustomCategories(isCustomCategories) {
          onUpdateTask({ UISettings: { ...UISettings, isCustomCategories } });
        }

        function setIsCustomLocations(isCustomLocations) {
          onUpdateTask({ UISettings: { ...UISettings, isCustomLocations } });
        }

        function onRegionChange(newRegion) {
          if (newRegion !== 'US' && ['companies_data', 'whitepages_phones'].some(service => enrichments.includes(service))) {
            const newEnrichments = enrichments.filter(el => !['companies_data', 'whitepages_phones'].includes(el));

            localStorage.setItem('googleMapsEnrichments', JSON.stringify(newEnrichments));
            onUpdateTask({ region: newRegion, enrichments: newEnrichments });
          } else {
            onUpdateTask({ region: newRegion });
          }

          onSetDefaultRegion(newRegion);
        }

        return <>
          <QueriesFormItems
            task={task}
            onUpdate={onUpdateTask}
            isCustomQueries={isCustomQueries}
            isCustomCategories={isCustomCategories}
            isCustomLocations={isCustomLocations}
            onIsCustomCategoriesChange={setIsCustomCategories}
            onIsCustomLocationsChange={setIsCustomLocations}
            customQueriesKey={CUSTOM_QUERIES_KEY}
            enrichmentFields={enrichmentFields}
          />

          {(isCustomLocations || isCustomQueries) &&
            <Form.Item className='formItem'>
              <Form.Item label={t('title.countryCustom', 'Country (data outside of this region will be ignored, necessary to parse postal code)')} className='inlineFormItem'>
                <RegionSelect size='large' value={region} onChange={onRegionChange}/>
              </Form.Item>
            </Form.Item>
          }
        </>;
      }}
      FormBodyExtra={({ task, updateTask, isPaidUser }) => {
        const {
          language, region, organizationsPerQueryLimit, service_name, skip_places, limit,
          dropDuplicates, enrichments, enrich, useZipCodes, UISettings = {}, dropEmailDuplicates, ignoreWithoutEmails,
        } = task;
        const { isCustomLocations } = UISettings;
        const isEnLanguage = language === 'en';

        const enrichEmailsAndContacts = enrichments.includes(domainsService);
        const enrichCompanyInsightsService = enrichments.includes(companyInsightsService);
        const enrichWhitepagesPhonesService = enrichments.includes(whitepagesPhonesService);
        const enrichEmailsValidatorService = enrichments.includes(emailsValidatorService);
        const isUSRegion = region === 'US';

        const supportsZipCodes = useSelector(state => state.queriesReducer.countrySupportsZipCodes)[region];

        function onUpdateTask(value) {
          clearTimeout(updateTimer);

          updateTimer = setTimeout(() => {
            updateUrlParams({ ...task, ...value });
          }, 500);

          updateTask(value);
        }

        function onSetLanguage(value) {
          onUpdateTask({ language: value });
          onSetDefaultLanguage(value);
        }

        function onChangeDropDuplicates() {
          const value = !dropDuplicates;
          onUpdateTask({ dropDuplicates: value });
          localStorage.setItem('dropDuplicates', value ? '1' : '0');
        }

        function onEnrichmentChange(enrichments) {
          onUpdateTask({ enrichments });
          localStorage.setItem('googleMapsEnrichments', JSON.stringify(enrichments));
        }

        function handleUseZipCodesChange() {
          onUpdateTask({ useZipCodes: !useZipCodes });
        }

        function onChangeIgnoreWithoutEmails() {
          updateTask({ ignoreWithoutEmails: !ignoreWithoutEmails, dropEmailDuplicates: ignoreWithoutEmails ? false : dropEmailDuplicates });
        }

        function onChangeDropEmailDuplicates() {
          updateTask({ dropEmailDuplicates: !dropEmailDuplicates });
        }

        function onChangeEnrichEmailsAndContacts() {
          onEnrichmentChange(enrichEmailsAndContacts ? enrichments.filter((el) => (el !== domainsService && el !== emailsValidatorService)) : [...enrichments, domainsService]);
        }

        function onChangeCompanyInsights() {
          onEnrichmentChange(enrichCompanyInsightsService ? enrichments.filter((el) => el !== companyInsightsService) : [...enrichments, companyInsightsService]);
        }

        function onChangeWhitepagesPhones() {
          onEnrichmentChange(enrichWhitepagesPhonesService ? enrichments.filter((el) => el !== whitepagesPhonesService) : [...enrichments, whitepagesPhonesService]);
        }

        function onChangeEmailsValidator() {
          onEnrichmentChange(enrichEmailsValidatorService ? enrichments.filter((el) => el !== emailsValidatorService) : [...enrichments, emailsValidatorService]);
        }

        function onChangeOrganizationsPerQueryInput(value) {
          onUpdateTask({ organizationsPerQueryLimit: value });
        }

        function onUpdateLimit(value) {
          onUpdateTask({ limit: value });
        }

        const debouncedOnUpdateLimit = debounce(onUpdateLimit, 200);

        return <>
          <Form.Item label={t('title.maxResults', 'Maximum results limit (0 = unlimited)')} className='limit-select'>
            <InputNumber
              min={0}
              step={1000}
              value={limit}
              onChange={debouncedOnUpdateLimit}
            />
            <InfoTooltip title={<Trans i18nKey='description.maxResults'>
              Parameter specifies the total limit of organizations that will be extracted
              from all search queries.
              <br/><br/>
              Although we don&apos;t have the exact amount of results before the task is finished, you can check our <a target='_blank' rel='noreferrer' href='https://outscraper.com/targetron'>partners&apos; directory</a>, to get an approximate amount of places by your criteria.
            </Trans>}/>
          </Form.Item>

          <Form.Item label={<Trans i18nKey='title.enrichments'>Enhance results with other services (<strong>email addresses</strong>, social media, more phones, legal names, employees, etc.)</Trans>} className='formItem enrichments-select'>
            <span className='nobreak'>
              <SelectService value={enrichments} onChange={onEnrichmentChange} serviceName={service_name} region={region} serviceOntologies={['domain', 'phone']} />
              <InfoTooltip title={<Trans i18nKey='description.gm.enrichments'>
                There is a limited amount of <a target='_blank' rel='noopener noreferrer' href='https://outscraper.com/google-maps-scraper/#dictionary'>fields</a> you can scrape from Google Maps. However, you can enrich the data with other services by adding the services you need to the scraping pipeline.
                <a target='_blank' rel='noopener noreferrer' href='https://outscraper.com/enrichment-services/'> Learn more</a>
                <br/><br/>

                The most common way of using it is enriching the results with <strong>Email & Contacts Scraper</strong>. This will search other public sources to get emails, contacts, social media, etc.
                <a target='_blank' rel='noreferrer' href='https://outscraper.com/find-contacts-google-place/'> Learn more</a> about how Outscraper bot finds emails.
              </Trans>}/>
            </span>
          </Form.Item>

          <Form.Item className='formItem'>
            <Form.Item className='inlineFormItem3'>
              <Checkbox checked={enrichEmailsAndContacts} onChange={onChangeEnrichEmailsAndContacts}><MailOutlined/> <Trans i18nKey='action.getEmails'>Extract <strong>emails</strong> & socials</Trans></Checkbox>
              <InfoTooltip paddingLeft={2} paddingRight={checkboxesPaddingRight} title={<>
                Google Maps doesn&apos;t include emails, but you can use the <strong>Email & Contacts Scraper</strong> by checking the checkbox.
                <br/><br/>
                This tool searches public sources for emails, contacts, and social media details.
                <a target='_blank' rel='noreferrer' href='https://outscraper.com/find-contacts-google-place/'> Learn more</a> about the Outscraper bot&apos;s email-finding method.
                <br/><br/>
                <a target='_blank' rel='noreferrer' href='https://outscraper.com/emails-scraper/#pricing'> Additional fees</a> will apply.
              </>}/>
            </Form.Item>

            <Form.Item className='inlineFormItem3'>
              <Checkbox checked={enrichCompanyInsightsService} onChange={onChangeCompanyInsights}><Trans i18nKey='action.getCompanyInsights'>Extract company insights</Trans></Checkbox>
              <InfoTooltip paddingLeft={2} paddingRight={checkboxesPaddingRight} title={<>
                Get company insights like legal name, employees, revenue, industry, founding year, etc.
                <br/><br/>
                Additional fees will apply.
              </>}/>
            </Form.Item>

            {isUSRegion && <Form.Item className='inlineFormItem3'>
              <Checkbox checked={enrichWhitepagesPhonesService} onChange={onChangeWhitepagesPhones}><Trans i18nKey='action.whitepagesPhones'>Phone owners</Trans></Checkbox>
              <InfoTooltip paddingLeft={2} paddingRight={checkboxesPaddingRight} title={<>
                Get information about phone owners from Whitepages.
                <br/><br/>
                Additional fees will apply.
              </>}/>
            </Form.Item>}

            <br/>
            {enrichEmailsAndContacts && <>
              <Form.Item className='inlineFormItem3'>
                <Checkbox checked={enrichEmailsValidatorService} onChange={onChangeEmailsValidator}><Trans i18nKey='description.enrichment.validateEmails'>Validate emails</Trans></Checkbox>
                <InfoTooltip paddingLeft={2} paddingRight={checkboxesPaddingRight} title={<>
                  Checks if emails are deliverable.
                  <br/><br/>
                  Additional fees will apply.
                </>}/>
              </Form.Item>

              <Form.Item className='inlineFormItem3'>
                <PremiumFeatureTooltip isPaidUser={isPaidUser}>
                  <Checkbox
                    disabled={!isPaidUser}
                    checked={ignoreWithoutEmails}
                    onChange={onChangeIgnoreWithoutEmails}
                  ><Trans i18nKey='action.ignoreWithoutEmails'>Ignore without emails</Trans></Checkbox>
                </PremiumFeatureTooltip>
                <InfoTooltip paddingLeft={2} paddingRight={checkboxesPaddingRight} title={<Trans i18nKey='description.ignoreWithoutEmails'>
                  Drop the places without at least one email found from your results (only if Emails & Contacts Scraper selected as enrichment).
                  <br/><br/>
                  If the amount of ignored rows are less than 5,000% of what was actually extracted, you won&apos;t be billed for ignored records.
                </Trans>}/>
              </Form.Item>

              <Form.Item className='inlineFormItem3'>
                <PremiumFeatureTooltip isPaidUser={isPaidUser}>
                  <Checkbox
                    disabled={!isPaidUser}
                    checked={dropEmailDuplicates}
                    onChange={onChangeDropEmailDuplicates}
                  ><Trans i18nKey='action.dropEmailDuplicates'>Delete email duplicates</Trans></Checkbox>
                </PremiumFeatureTooltip>
                <InfoTooltip paddingLeft={2} paddingRight={checkboxesPaddingRight} title={<Trans i18nKey='description.dropEmailDuplicates'>
                  Drop the places with the same email (e.g. when the same company has many locations).
                  <br/><br/>
                  If the amount of ignored rows are less than 5,000% of what was actually extracted, you won&apos;t be billed for ignored records.
                </Trans>}/>
              </Form.Item>
            </>}
          </Form.Item>
          <br/>

          <AdvancedParameters header={<Trans i18nKey='action.advancedParameters'>Advanced parameters (<strong>filters</strong>, <strong>language</strong>, limit per query, etc.)</Trans>}>
            {!enrich && <><Form.Item
              label={<Trans i18nKey='title.filters'>Filters (return only relevant results)</Trans>}
              className='formItem'
              tooltip={{ title: <Trans i18nKey='description.filters'>
                Sometimes Google adds other categories to your search.
                Filters allow you to ignore such categories and pay only for the matched items.
                For example, when you search by category &quot;restaurant&quot; you might see similar categories like bars, pizza, etc.
                Using `subtypes` contains &quot;restaurant&quot; filter will return only the restaurants and ignore other categories.
                <a target='_blank' rel='noopener noreferrer' href='https://outscraper.com/google-maps-data-scraper-filters/'> <Trans i18nKey='action.learnMore'>Learn more</Trans></a>
                <br/><br/>
                If the amount of ignored rows are less than 5,000% of what was actually extracted, you won&apos;t be billed for ignored records.
                <br/><br/>
                When using filters, the estimate of the results will be very approximate.
                <br/><br/>
                It might drastically increase the execution time, especially when using the total limit.
              </Trans>, icon: <QuestionCircleTwoTone/> }}
            >
              <Tooltip title={isEnLanguage || isAdvancedUser ? '' : t('description.languageDisabled', 'Works only for EN language')}>
                &nbsp;
                <Filters
                  value={task.filters}
                  exactMatch={task.exactMatch}
                  onChange={updateTask}
                  disabled={!isEnLanguage && !isAdvancedUser}
                  region={region}
                  isPaidUser={isPaidUser}
                />
                <br/><br/>
                <p className='help-p1' style={{ paddingLeft: 10 }}><a target='_blank' rel='noopener noreferrer' href='https://outscraper.com/google-maps-data-scraper-filters/'><Trans i18nKey='description.howToUseFilters'>How to use filters</Trans>?</a></p>
              </Tooltip>
            </Form.Item>
            <br/></>}

            <Form.Item label={t('title.language', 'Language')} className='inlineFormItem'>
              <LanguageSelect onChange={onSetLanguage} value={language}/>
            </Form.Item>
            {!enrich && <>
              <Form.Item label={t('title.organizationsPerQueryLimit', 'Places per one query search')} className='inlineFormItem'>
                <OrganizationsPerQueryInput
                  value={organizationsPerQueryLimit}
                  onChange={onChangeOrganizationsPerQueryInput}
                />
              </Form.Item>
              <Form.Item label={t('title.Skip', 'Skip')} className='inlineFormItem'>
                <Select
                  allowClear
                  value={skip_places}
                  style={{ width: 70 }}
                  onChange={(v) => updateTask({ skip_places: v })}
                >
                  <Option key='0' value={0}>0</Option>
                  <Option key='20' value={20}>20</Option>
                  <Option key='40' value={40}>40</Option>
                  <Option key='60' value={60}>60</Option>
                  <Option key='80' value={80}>80</Option>
                </Select>
                <InfoTooltip title={t('description.skip', 'Parameter specifies the number of items to skip. Commonly used in pagination')}/>
              </Form.Item>
            </>}

            {!enrich && <>
              <Form.Item className='formItem'>
                <Checkbox
                  checked={dropDuplicates}
                  onChange={onChangeDropDuplicates}
                ><Trans i18nKey='action.dropDuplicates'>Delete duplicates</Trans></Checkbox>
                <InfoTooltip paddingLeft={2} title={<Trans i18nKey='description.dropDuplicates'>
                  Drop the same organizations from different queries by `google_id` column.
                  <br/><br/>
                  If the amount of ignored rows are less than 5,000% of what was actually extracted, you won&apos;t be billed for ignored records.
                </Trans>}/>
              </Form.Item>

              {!isCustomLocations && <Form.Item className='formItem'>
                <Checkbox
                  checked={useZipCodes && supportsZipCodes}
                  disabled={!supportsZipCodes}
                  onChange={handleUseZipCodesChange}
                ><Trans i18nKey='title.useZipCodes'>Use zip codes</Trans></Checkbox>
                <InfoTooltip paddingLeft={2} title={<Trans i18nKey='description.zipCodes'>
                  As Google shows only up to 500 places per one query search, you might want to use zip codes for densely populated areas/categories. Use this option to make the scraper search by `restaurant, 10001, NY, US...` instead of `restaurants, New York, US`.
                  <br/><br/>
                  This will increase the coverage and execution time. Please visit <a href='https://outscraper.com/google-maps-scrape-all-places' target='_blank' rel='noopener noreferrer'>this page</a> to learn more.
                </Trans>}/>
              </Form.Item>}
            </>}
          </AdvancedParameters>
        </>;
      }}
    />
  );
}

GoogleMapsScraper.propTypes = {
  version: PropTypes.number,
};
