import React from 'react';
import { Form, InputNumber, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';

import history from '../../utils/history';
import { formatTaskQueries, deformatTaskQueries, isMapsTaskReady } from './Common/Google/utils';
import BaseService from './Common/BaseService';
import InfoTooltip from '../../shared/Components/InfoTooltip';
import AdvancedParameters from './Common/AdvancedParameters';
import QueriesFormItems, { getDefaultIsCustomCategories, getDefaultIsCustomLocations } from './Common/Google/QueriesFormItems';
import RegionSelect from './Common/Google/RegionSelect';
import OrganizationsPerQueryInput from './Common/Google/OrganizationsPerQueryInput';
import { enrichmentFields } from './GoogleMaps';

const CUSTOM_QUERIES_KEY = 'photos/customQueries';
const { Option } = Select;

export default function GoogleMapsPhotos() {
  const { query: urlQuery, gl: urlRegion } = queryString.parse(history.location.search);
  const taskExtraDefaultParams = {
    queries: urlQuery ? urlQuery : '',
    categories: [],
    locations: [],

    customCategories: '',
    customLocations: '',

    language: 'en',
    region: (urlRegion && urlRegion !== 'undefined') ? urlRegion : undefined,

    photosPerOrganizationLimit: 250,
    organizationsPerQueryLimit: 1,

    UISettings: {
      isCustomQueries: urlQuery ? true :(localStorage.getItem(CUSTOM_QUERIES_KEY) ? localStorage.getItem(CUSTOM_QUERIES_KEY) === '1' : true),
      isCustomCategories: getDefaultIsCustomCategories(),
      isCustomLocations: getDefaultIsCustomLocations(),
    },
  };

  const { t } = useTranslation();

  function formatTask(task) {
    const { enrich, UISettings = {} } = task;
    const { isCustomQueries, isCustomCategories, isCustomLocations } = UISettings;

    if (enrich) {
      task.organizationsPerQueryLimit = 1;
      task.region = undefined;
    }

    return formatTaskQueries({ ...task }, isCustomQueries, isCustomCategories, isCustomLocations);
  }

  function deformatTask(task) {
    const { UISettings = {} } = task;
    const { isCustomQueries, isCustomCategories, isCustomLocations } = UISettings;
    return deformatTaskQueries(task, isCustomQueries, isCustomCategories, isCustomLocations);
  }

  return (
    <BaseService
      title='Google Maps Photos Scraper'
      subTitle='Returns photos from places on Google Maps'
      serviceName='google_maps_photos_service_v3'
      unitName='photo'
      learnMoreUrl='https://outscraper.com/google-maps-photos-scraper'
      apiTag='Google/paths/~1maps~1photos-v3/get'
      taskExtraDefaultParams={taskExtraDefaultParams}
      isTaskReady={isMapsTaskReady}
      formatTask={formatTask}
      deformatTask={deformatTask}
      taskUpdateAfterSubmit={{ queries: '', categories: [], customCategories: '', input_file: null, enrich: false, tags: [] }}
      FormBody={({ task, updateTask }) => {
        const { UISettings = {} } = task;
        const { isCustomQueries, isCustomCategories, isCustomLocations } = UISettings;

        function setIsCustomCategories(isCustomCategories) {
          updateTask({ UISettings: { ...UISettings, isCustomCategories } });
        }

        function setIsCustomLocations(isCustomLocations) {
          updateTask({ UISettings: { ...UISettings, isCustomLocations } });
        }

        return <>
          <QueriesFormItems
            task={task}
            onUpdate={updateTask}
            isCustomQueries={isCustomQueries}
            isCustomCategories={isCustomCategories}
            isCustomLocations={isCustomLocations}
            onIsCustomCategoriesChange={setIsCustomCategories}
            onIsCustomLocationsChange={setIsCustomLocations}
            customQueriesKey={CUSTOM_QUERIES_KEY}
            enrichmentFields={enrichmentFields}
          />
        </>;
      }}
      FormBodyExtra={({ task, updateTask }) => {
        const { region, enrich, organizationsPerQueryLimit, photosPerOrganizationLimit, tag = '', UISettings = {} } = task;
        const { isCustomQueries, isCustomLocations } = UISettings;

        return <>
          <Form.Item className='formItem'>
            <Form.Item label={t('title.photosPerPlace', 'Photos limit per one place')} className='inlineFormItem'>
              <InputNumber
                min={1}
                step={100}
                value={photosPerOrganizationLimit}
                onChange={(v) => updateTask({ photosPerOrganizationLimit: parseInt(v) || 1 })}
              />
              <InfoTooltip title='Parameter specifies the limit of photos for one organization.'/>
            </Form.Item>

            {!enrich && <Form.Item label={t('title.organizationsPerQueryLimit', 'Places per one query search')} className='inlineFormItem'>
              <OrganizationsPerQueryInput
                value={organizationsPerQueryLimit}
                onChange={(v) => updateTask({ organizationsPerQueryLimit: v })}
              />
            </Form.Item>}

            <Form.Item label={t('title.filterByTag', 'Filter by tag')} className='inlineFormItem'>
              <Select
                allowClear
                autoFocus
                showSearch
                placeholder='Select tag filter'
                value={tag}
                style={{ width: 150 }}
                onChange={(v) => updateTask({ tag: v })}
              >
                <Option key='all' value=''>All</Option>
                <Option key='latest' value='latest'>Latest</Option>
                <Option key='menu' value='menu'>Menu</Option>
                <Option key='by_owner' value='by_owner'>By owner</Option>
              </Select>
            </Form.Item>
          </Form.Item>

          {(isCustomLocations || isCustomQueries) && !enrich &&
            <AdvancedParameters>
              <Form.Item className='formItem'>
                <Form.Item label={t('title.countryCustom', 'Country (data outside of this region will be ignored, necessary to parse postal code)')} className='inlineFormItem'>
                  <RegionSelect value={region} onChange={(v) => updateTask({ region: v })}/>
                </Form.Item>
              </Form.Item>
            </AdvancedParameters>
          }
        </>;
      }}
    />
  );
}

GoogleMapsPhotos.propTypes = {};
