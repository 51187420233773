import React from 'react';
import { Form, DatePicker, InputNumber, Select } from 'antd';
import dayjs from 'dayjs';

import BaseService from './Common/BaseService';
import plainQueriesSwitchFormHeader from './Common/Google/plainQueriesSwitchFormHeader';
import StartStopLocationsSelect from './Common/Google/TrafficStartStopLocationsSelect';
import CustomQueries from './Common/Selects/CustomQueries';
import InfoTooltip from '../../shared/Components/InfoTooltip';

const CUSTOM_QUERIES_KEY = 'traffic/customQueries';
const { Option } = Select;

export default function GoogleMapsExtractor() {
  const taskExtraDefaultParams = {
    queries: [['', '']],
    plainQueries: '',
    dateFrame: [dayjs().startOf('hour'), dayjs().endOf('hour')],

    interval: 60,

    UISettings: {
      isCustomQueries: localStorage.getItem(CUSTOM_QUERIES_KEY) === '1',
    },
  };

  function formatTask(task) {
    const { queries, plainQueries, dateFrame, interval, UISettings = {} } = task;
    const { isCustomQueries } = UISettings;

    return { ...task,
      queries: isCustomQueries ? formatPlainQueries(plainQueries) : queries,
      initial_time: dateFrame[0].unix(),
      finish_time: dateFrame[1].unix(),
      interval,
    };
  }

  function formatPlainQueries(text) {
    const results = [];
    text.split(/\r?\n/).forEach(row => {
      const items = row.split(/ {4}|\t/);

      if (items.length === 2) {
        results.push([items[0], items[1]]);
      }
    });
    return results;
  }

  function deformatTask(task) {
    const { initial_time, finish_time, plainQueries = '' } = task;
    return { ...task, plainQueries, dateFrame: [dayjs(initial_time*1000), dayjs(finish_time*1000)] };
  }

  function handleIsTaskReady(task) {
    const { queries, plainQueries, input_file, dateFrame, interval, UISettings = {} } = task;
    const { isCustomQueries } = UISettings;

    if (!interval) return false;
    if (!dateFrame || dateFrame.length !== 2) return false;

    if (isCustomQueries) {
      if (input_file) return true;
      if (plainQueries.length < 3) return false;

    } else {
      if (!queries.some(l => l[0] !== '' && l[1] !== '')) {
        return false;
      }
    }

    return true;
  }

  return (
    <BaseService
      title='Google Maps Traffic Scraper'
      subTitle='Returns directions between two points'
      serviceName='google_maps_traffic_service'
      unitName='request'
      learnMoreUrl='https://outscraper.com/google-maps-traffic-extractor'
      tutorialLink='https://outscraper.com/scrape-google-maps-traffic'
      apiTag='Google/paths/~1maps~1directions/get'
      taskUpdateAfterSubmit={{ queries: [['', '']], plainQueries: '', tags: [] }}
      taskExtraDefaultParams={taskExtraDefaultParams}
      isTaskReady={handleIsTaskReady}
      formatTask={formatTask}
      deformatTask={deformatTask}
      FormHeader={({ task, updateTask }) => <div style={{ maxWidth: '950px' }}>{plainQueriesSwitchFormHeader(task, updateTask, CUSTOM_QUERIES_KEY)}</div>}
      FormBody={({ task, updateTask }) => {
        const { queries, plainQueries, inputFile, dateFrame, interval, travel_mode = '', UISettings = {}, settings } = task;
        const { isCustomQueries } = UISettings;

        function onFileUploaded(input_file, enrich, output_extension) {
          updateTask({ input_file, enrich, queries: '', settings: { ...settings, output_extension } });
        }

        return <>
          {isCustomQueries ? (
            <Form.Item
              className='formItem'
              style={{ maxWidth: 800 }}
              label={<span>
                Queries, Coordinates or Places splitted by tab or 4 spaces
                <InfoTooltip title='You can use anything that will work on Google Maps site'/>
              </span>}
            >
              <CustomQueries
                value={plainQueries}
                onChange={(v) => updateTask({ plainQueries: v })}
                inputFile={inputFile}
                onFileUploaded={onFileUploaded}
                placeholder='17.8805168994, -76.9060696994	17.8810785996, -76.9055896991&#10;Intersection of 19th Ave and Santiago St, San Francisco    Intersection of 19th Ave and Irving St, San Francisco'
              />
              <br/>
              <br/>
            </Form.Item>
          ) : (
            <Form.Item required label='Start - stop locations:' className='formItem'>
              <StartStopLocationsSelect locations={queries} onChange={(v) => updateTask({ queries: v })}/>
            </Form.Item>
          )}

          <Form.Item className='formItem'>
            <Form.Item label='Time frame:' className='inlineFormItem'>
              <DatePicker.RangePicker
                separator='-'
                value={dateFrame}
                ranges={{
                  Hour: [dayjs().startOf('hour'), dayjs().endOf('hour')],
                  Day: [dayjs().startOf('day'), dayjs().endOf('day')],
                  Week: [dayjs().startOf('week'), dayjs().endOf('week')],
                  Month: [dayjs().startOf('month'), dayjs().endOf('month')],
                }}
                showTime
                format='YYYY/MM/DD HH:mm:ss'
                onChange={(v) => updateTask({ dateFrame: v })}
              />
            </Form.Item>

            <Form.Item label='Time interval (min):' className='inlineFormItem'>
              <InputNumber value={interval} onChange={(v) => updateTask({ interval: v })} min={1}/>
              <InfoTooltip title={<>
                Parameter specifies the interval to use between departure time and finish time.
              </>}/>
            </Form.Item>
          </Form.Item>

          <Form.Item label='Travel Mode'>
            <Select value={travel_mode} style={{ width: 200 }} onChange={(v) => updateTask({ travel_mode: v })}>
              <Option key='' value=''>Best</Option>
              <Option key='car' value='car'>Driving</Option>
              <Option key='transit' value='transit'>Transit</Option>
              <Option key='walk' value='walk'>Walking</Option>
              <Option key='bike' value='bike'>Cycling</Option>
              <Option key='flight' value='flight'>Flights</Option>
            </Select>
          </Form.Item>
        </>;
      }}
    />
  );
}
