import React from 'react';

import {isMapsTaskReady } from './Common/Google/utils';
import SimpleService from './Common/SimpleService';
import YelpQueriesFormItems from './Common/YelpQueriesFormItems';

export default function YelpScraper() {
  return (
    <SimpleService
      unitName='business'
      title='Y.E.L.P Businesses Scraper'
      subTitle='Returns data from a list of businesses'
      serviceName='yelp_service'
      limitType='total'
      learnMoreUrl='https://outscraper.com/yelp-scraper'
      apiTag='Businesses-and-POI/paths/~1yelp-search/get'
      fields={['biz_id', 'name', 'price_range', 'rating', 'reviews', 'categories', 'neighborhoods', 'phone', 'website', 'latitude', 'longitude', 'city', 'state', 'country', 'business_url', 'services', 'photo', 'tags', 'snippet']}
      supportedEnrichmentServices={[]}
      serviceOntologies={['domain', 'phone', 'address']}
      isTaskReady={isMapsTaskReady}
      defaultParams={{
        categories: [],
        locations: [],
        UISettings: {
          isCustomQueries: true,
          isCustomCategories: false,
          isCustomLocations: false,
        },
      }}
      QueriesFormItems={({ task, updateTask }) => {
        const { UISettings = {} } = task;
        const { isCustomQueries, isCustomCategories, isCustomLocations } = UISettings;

        function setIsCustomCategories(isCustomCategories) {
          updateTask({ UISettings: { ...UISettings, isCustomCategories } });
        }

        function setIsCustomLocations(isCustomLocations) {
          updateTask({ UISettings: { ...UISettings, isCustomLocations } });
        }

        return <>
          <YelpQueriesFormItems
            singleRegion='US'
            task={task}
            onUpdate={updateTask}
            isCustomQueries={isCustomQueries}
            isCustomCategories={isCustomCategories}
            isCustomLocations={isCustomLocations}
            onIsCustomCategoriesChange={setIsCustomCategories}
            onIsCustomLocationsChange={setIsCustomLocations}
            customQueriesKey={'customQueries'}
          />
        </>;
      }}
    />
  );
}
