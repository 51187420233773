import React from 'react';
import PropTypes from 'prop-types';
import { Spin, Alert, Col, Row, Statistic } from 'antd';
import config from 'react-global-configuration';
import { Trans } from 'react-i18next';

import InfoTooltip from '../../../shared/Components/InfoTooltip';
import { getEstimateDuration } from '../../../utils/utils';
import Href from '../../../shared/Components/Href';

const queriesSoftLimit = config.get('queriesSoftLimit');
const queriesHardLimit = config.get('queriesHardLimit');
const valueStyle = { fontSize: 14, fontWeight: 'bold' };

export default function TaskConfirmationInfo({ taskValidationResult, loading, splitInto = 1, enrichments }) {
  const { preview_queries = [], total_queries = 1, estimates = {}, input_file, enrich, power, positive_balance } = taskValidationResult;
  const { duration, results_amount, cost, new_balance } = estimates;
  const previewCount = preview_queries.length;
  const more = (total_queries - previewCount).toLocaleString();
  const enrichmentCostNotice = enrichments && enrichments.length > 0 && !power;
  const split = splitInto > 1;

  const queriesLimitWarning = total_queries > queriesSoftLimit && !split;
  const queriesLimitError = total_queries > queriesHardLimit && !split;

  return <>
    {input_file ? (
      <>
        <h4>
          You&apos;re going to {enrich ? 'enrich the data inside' : 'extract the data from the queries inside'} the uploaded file: {decodeURI(input_file)}
        </h4>

        <br/>
        <p className='help-p1'>
          * Use up to 50k queries per single task to achieve better performance.
        </p>
      </>
    ) : (
      <>
        <h4><Trans i18nKey='title.confirmQueries'>You&apos;re going to process the following queries</Trans>: &nbsp;<Spin spinning={loading}/></h4>

        {!loading && preview_queries.map((r, index) =>
          <div style={{ marginLeft: 10 }} key={index}><Href external href={r.link}>{r.title}</Href><br/></div>
        )}
        {(total_queries > previewCount && !loading) ? <p style={{ marginLeft: 10 }}>... <Trans i18nKey='description.moreQueries'><strong>{{more}}</strong> more queries</Trans></p> : <br/>}
      </>
    )}

    {(duration || results_amount) &&
      <>
        <Row style={{ marginLeft: 5 }} gutter={16}>
          <Col span={9}>
            {duration &&
              <Statistic
                title={<strong><Trans i18nKey='title.сompletion'>Finish</Trans></strong>}
                valueStyle={valueStyle}
                value={getEstimateDuration(duration, splitInto)}
              />}
          </Col>
          <Col span={15}>
            {results_amount &&
              <Statistic
                title={<><Trans i18nKey='description.veryRoughtEstimate'><strong>Very rough</strong> estimate of usage</Trans><InfoTooltip
                  title={<>
                    This is a very rough amount of the usage based on the number of queries and filters selected.
                    <br/><br/>
                    The actual amount of the results might be different.
                    <br/><br/>
                    You can use limits to set up the maximum results amount.
                  </>}
                /></>}
                valueStyle={valueStyle}
                value={estimates.results_amount_label || `${results_amount}`}
              />}
          </Col>
        </Row>
        <br/>
      </>
    }

    {(!!cost || (!!new_balance && new_balance < 0)) &&
      <>
        <Row style={{ marginLeft: 5 }} gutter={16}>
          <Col span={9}>
            {!!cost && <Statistic
              title={<><Trans i18nKey='description.roughCost'><strong>Rough cost</strong></Trans>{enrichmentCostNotice && <> (<Trans i18nKey='description.roughCostEnrichments'>enrichments included</Trans>)</>}<InfoTooltip title={<>
                This is a very rough estimate based on the number of queries and filters selected.
                <br/><br/>
                The actual cost might be different.
                <br/><br/>
                You will be charged only for the actual number of items extracted.
                <br/><br/>
                You can use limits to set up the maximum results amount.
              </>}/></>}
              valueStyle={valueStyle}
              value={estimates.cost_label || `$${cost}`}
            />}
          </Col>
          <Col span={15}>
            {!!new_balance && new_balance < 0 && <Statistic
              title={<Trans i18nKey='description.estimatedBalance'>Estimated balance after finished task</Trans>}
              valueStyle={valueStyle}
              value={new_balance}
            />}
          </Col>
        </Row>
        <br/>
      </>
    }

    {(results_amount || !!cost) && <>
      <p className='help-p1' style={{ marginLeft: 10 }}>
        * <Trans i18nKey='description.roughEstimate'>This is a very rough estimate, the actual amount may vary. The cost depends on the volume of data actually received.</Trans>.
      </p>
    </>}

    {estimates.free_tier_exceeded && !(positive_balance && new_balance < 0) && <>
      <br/>
      <Alert
        showIcon
        type='warning'
        message={(<Trans i18nKey='message.exceededFreeTier'>
          <a target='_blank' rel='noopener noreferrer' href={config.get('pricingUrl')}>Free tier</a>
          &nbsp;might be exceeded. By starting the task, you agree with&nbsp;
          <a target='_blank' rel='noopener noreferrer' href={config.get('pricingUrl')}>pricing</a>
          &nbsp;and&nbsp;
          <a target='_blank' rel='noopener noreferrer' href={config.get('termsOfServiceUrl')}>terms of service</a>.
        </Trans>)}
      />
    </>}

    {positive_balance && new_balance < 0 && !loading && <>
      <br/>
      <Alert
        showIcon
        type='warning'
        message={<Trans i18nKey='message.exceedCredits'>
          This task might exceed your credits. By starting the task, you confirm that you agree that this task may lead to additional billing.
        </Trans>}
      />
    </>}

    {queriesLimitWarning && !loading && !queriesLimitError && <>
      <br/>
      <Alert
        showIcon
        type='warning'
        message={<Trans i18nKey='message.splitTask'>
          It&apos;s recommended to split the task into subtasks in order to speed up the
          process and improve its reliability.
        </Trans>}
      />
    </>}

    {queriesLimitError && !loading && <>
      <br/>
      <Alert
        showIcon
        type='error'
        message={<>
          <Trans i18nKey='message.splitTaskReqired'>Please split the task into subtasks to reduce the number of queries per task below</Trans> <strong>{queriesHardLimit.toLocaleString()}</strong>.
        </>}
      />
    </>}

    {split && <>
      <br/>
      <Alert
        showIcon
        type='warning'
        message={<Trans i18nKey='message.splitedTask'>
          Your task will be divided into <strong>{{splitInto}}</strong>+ subtasks. Be aware that some features like <strong>Delete duplicates</strong>, <strong>Results limit</strong>, etc. might work only inside each task.
        </Trans>}
      />
    </>}
  </>;
}

TaskConfirmationInfo.propTypes = {
  taskValidationResult: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  splitInto: PropTypes.number,
  enrichments: PropTypes.array,
};
