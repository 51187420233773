import React from 'react';

import SimpleService from './Common/SimpleService';

export default function AirbnbSearch() {
  return (
    <SimpleService
      unitName='record'
      title='Airbnb Search Scraper'
      subTitle='Returns search results'
      queriesLabel='Search URLs'
      serviceName='airbnb_search'
      learnMoreUrl='https://outscraper.com/airbnb-scraper/'
      queriesPlaceholder='https://www.airbnb.com/s/Italy/homes?tab_id=...&#10;https://www.airbnb.com/s/Paris/homes?tab_id=...'
      apiTag='Airbnb'
    />
  );
}
