import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Popconfirm, message } from 'antd';
import { RedoOutlined, CloseCircleOutlined, ClockCircleOutlined, CopyOutlined, LinkOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { encode } from 'js-base64';

import Mixpanel from '../../analytics/mixpanel';
import { isPaidUser } from '../../utils/utils';
import PremiumFeatureTooltip from '../../shared/Components/PremiumFeatureTooltip';

const StyledButton = styled(Button)`
  margin: 0px 2px;
  width: 10px;
`;
StyledButton.defaultProps = { type: 'default', size: 'small', shape: 'round' };

export default function TaskActions({ task = {}, onRerun = null, onRecreate = null, onRemove = null, onSchedule = null }) {
  const { id, metadata = {}, status, created } = task;
  const restarted = status === 'RESTARTED';
  const isSuccess = status === 'SUCCESS';
  const isRerunnable = (metadata.queries || (metadata.locations && metadata.categories) && !restarted);
  const isOldTask = !restarted && Math.abs(new Date() - new Date(created)) / 36e5 > 24;
  const paidUser = isPaidUser();
  const { t } = useTranslation();

  function handleOnRecreate() {
    onRecreate(metadata);
  }

  function handleOnRerun() {
    onRerun(id);
  }

  function handleOnSchedule() {
    onSchedule(id);
  }

  function handleOnRemove() {
    onRemove(id);
  }

  function handleShare() {
    Mixpanel.track('Share task');

    navigator.clipboard.writeText(`${window.origin}/shared/${encode(localStorage.getItem('id'))}/${id}`);

    message.success('The public link to the task is copied to your clipboard');
  }

  return <>
    { onRerun &&
      <Popconfirm
        title={t('action.restart', 'Restart') + '?'}
        okText={t('action.ok', 'Ok')}
        cancelText={t('action.cancel', 'Cancel')}
        onConfirm={handleOnRerun}
        disabled={!isRerunnable}
      >
        <StyledButton
          className='restart-task'
          title={t('action.restart', 'Restart')}
          icon={<RedoOutlined />}
          disabled={!isRerunnable || restarted || (!isOldTask && !isSuccess)}
        />
      </Popconfirm>
    }
    { onRecreate &&
      <StyledButton
        className='copy-task'
        title={t('action.useAsTemplate', 'Use as a template')}
        icon={<CopyOutlined />}
        disabled={!isRerunnable}
        onClick={handleOnRecreate}
      />
    }
    { onSchedule &&
      <PremiumFeatureTooltip hideIcon isPaidUser={paidUser}>
        <StyledButton
          className='schedule-task'
          title={t('action.schedule', 'Schedule')}
          icon={<ClockCircleOutlined />}
          onClick={handleOnSchedule}
          disabled={!isRerunnable || !paidUser}
        />
      </PremiumFeatureTooltip>
    }
    <StyledButton
      className='share-task'
      title={t('action.share', 'Share')}
      icon={<LinkOutlined />}
      disabled={!isSuccess}
      onClick={handleShare}
    />
    { onRemove &&
      <Popconfirm
        title={t('action.remove', 'Remove') + '?'}
        okText={t('action.ok', 'Ok')}
        cancelText={t('action.cancel', 'Cancel')}
        onConfirm={handleOnRemove}
      >
        <StyledButton
          className='remove-task'
          title={t('action.remove', 'Remove')}
          icon={<CloseCircleOutlined />}
          disabled={restarted || (!isOldTask && !isSuccess)}
        />
      </Popconfirm>
    }
  </>;
}

TaskActions.propTypes = {
  task: PropTypes.object.isRequired,
  onRerun: PropTypes.func,
  onRecreate: PropTypes.func,
  onRemove: PropTypes.func,
  onSchedule: PropTypes.func,
  onShare: PropTypes.func,
};
