import React from 'react';

import { services } from '../../shared/data/services';
import SimpleService from './Common/SimpleService';

export default function TrustpilotScraper() {
  return (
    <SimpleService
      limitType={null}
      unitName='biz'
      title='Trustpilot Scraper'
      subTitle='Returns data from a list of businesses'
      serviceName='trustpilot_service'
      queriesPlaceholder='https://www.trustpilot.com/review/outscraper.com&#10;outscraper.com'
      learnMoreUrl='https://outscraper.com/trustpilot-scraper'
      apiTag='Trustpilot/paths/~1trustpilot/get'
      enrichmentFields={services.trustpilot_service.input_fields}
      supportedEnrichmentServices={[]}
    />
  );
}
