import React from 'react';
import { Tooltip, Badge } from 'antd';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';

import { showConfirm } from './ReasonsToAddCredits';
import PremiumFeatureIcon from './PremiumFeatureIcon';

export default function PremiumFeatureTooltip({ isPaidUser, children, hideIcon=false }) {
  function onShowReasonsToAddCredits() {
    if (!isPaidUser) showConfirm(true);
  }

  return <Tooltip onClick={onShowReasonsToAddCredits} title={isPaidUser ? '' : <>
    <a onClick={onShowReasonsToAddCredits}><Trans i18nKey='action.premiumFeature'>Add credits or close open invoices to use this feature</Trans></a> <PremiumFeatureIcon />
    <br/><br/>
    <Trans i18nKey='description.whyAddCredits3'>Enhance your data analytics capabilities by adding credits to your account. This enables access to a broader range of records, offering deeper, more comprehensive insights and a richer understanding of your data landscape.</Trans>
  </>}>
    <Badge count={isPaidUser || hideIcon ? null : <><PremiumFeatureIcon />&nbsp;</>}>
      {children}
    </Badge>
  </Tooltip>;
}

PremiumFeatureTooltip.propTypes = {
  isPaidUser: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  children: PropTypes.node.isRequired,
  hideIcon: PropTypes.bool,
};
