import React from 'react';
import PropTypes from 'prop-types';
import { Form, InputNumber, Checkbox } from 'antd';

import { services } from '../../shared/data/services';
import SimpleService from './Common/SimpleService';
import PreferredContactsItem from './Common/PreferredContactsItem';

export default function ContactsFinder() {
  return (
    <SimpleService
      limitType={null}
      unitName={services.contacts_finder_service.unit}
      title={services.contacts_finder_service.name}
      subTitle={services.contacts_finder_service.description}
      serviceName='contacts_finder_service'
      queriesPlaceholder='company.com&#10;company2.com'
      queriesLabel='Domains or URLs'
      defaultParams={{ limit_per_company: 5 }}
      ExtraItems={({ updateTask, task }) => <ExtraItems updateTask={updateTask} task={task} />}
    />
  );
}

export function ExtraItems({ updateTask, task }) {
  const { limit_per_company = 3, ignore_without_title, ignore_without_linkedin } = task;

  return <>
    <PreferredContactsItem value={task} onChange={updateTask} isPaidUser/>
    <Form.Item className='formItem'>
      <Checkbox
        checked={ignore_without_title}
        onChange={() => updateTask({ ignore_without_title: !ignore_without_title })}
      >Only with title</Checkbox>

      <Checkbox
        checked={ignore_without_linkedin}
        onChange={() => updateTask({ ignore_without_linkedin: !ignore_without_linkedin })}
      >Only with LinkedIn</Checkbox>
    </Form.Item><br/>
    <Form.Item label='Number of contacts per one company' className='formItem'>
      <InputNumber
        min={1}
        step={1}
        max={100}
        value={limit_per_company}
        onChange={(v) => updateTask({ limit_per_company: parseInt(v) || 1 })}
      />
    </Form.Item><br/>
  </>;
}

ExtraItems.propTypes = {
  updateTask: PropTypes.func.isRequired,
  task: PropTypes.object.isRequired,
};
