import React from 'react';
import { Form, Select, InputNumber} from 'antd';
import { Trans } from 'react-i18next';
import styled from 'styled-components';

import { services } from '../../shared/data/services';
import SimpleService from './Common/SimpleService';
import RegionSelect from './Common/Google/RegionSelect';

const { Option } = Select;
const StyledButton = styled.button`
  padding-left: 6px;
  color: #262626;
  text-decoration: underline;
`;
const StyledDiv = styled.div`
  margin-top: -10px;
  margin-left: 10px;
`;

export default function Screenshoter() {
  return (
    <SimpleService
      limitType={null}
      unitName={services.screenshoter.unit}
      title={services.screenshoter.name}
      subTitle={services.screenshoter.description}
      serviceName='screenshoter'
      queriesPlaceholder='https://outscraper.com&#10;https://outscraper.com/pricing'
      queriesLabel='URLs'
      apiTag='Other-Services/paths/~1screenshoter/get'
      enrichmentFields={services.screenshoter.input_fields}
      defaultParams={{ region: 'US', type: 'webp', width: 1200, height: 800 }}
      ExtraItems={({ updateTask, task }) => {
        const { region, type, width, height } = task;

        return <>
          <Form.Item label='IP Region'>
            <RegionSelect value={region} onChange={(v) => updateTask({ region: v })}/>
          </Form.Item>

          <Form.Item label='Image Extension'>
            <Select
              style={{ width: 100 }}
              value={type}
              onChange={(v) => updateTask({ type: v })}
            >
              <Option value='webp'>WEBP</Option>
              <Option value='png'>PNG</Option>
              <Option value='jpeg'>JPEG</Option>
            </Select>
          </Form.Item>

          <Form.Item className='formItem'>
            <Form.Item label='Width (px)' className='inlineFormItem'>
              <InputNumber placeholder='1200' min={100} max={1920} value={width} onChange={(v) => updateTask({ width: v })}/>
            </Form.Item>
            <Form.Item label='Height (px)' className='inlineFormItem'>
              <InputNumber placeholder='800' min={100} max={1080} value={height} onChange={(v) => updateTask({ height: v })}/>
            </Form.Item>
          </Form.Item>
          <StyledDiv>
            <label><Trans i18nKey='title.try'>Try</Trans>: </label>
            <StyledButton
              type='button'
              className='link-button'
              onClick={() => updateTask({ width: 1920, height: 1080 })}
            >FHD,</StyledButton>
            <StyledButton
              type='button'
              className='link-button'
              onClick={() => updateTask({ width: 1280, height: 720 })}
            > HD,</StyledButton>
            <StyledButton
              type='button'
              className='link-button'
              onClick={() => updateTask({ width: 640, height: 480 })}
            > SD</StyledButton>
          </StyledDiv>
        </>;
      }}
    />
  );
}
